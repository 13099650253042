import React from 'react';
import Auth from './Auth';

function App() {
  return (
    <React.Fragment>
      <Auth />
    </React.Fragment>
  );
}

export default App;
