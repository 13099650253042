import React, { Component } from 'react';

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import authServiceDocs from '../../reference/auth_service/openapi.yaml';
import paymentProcessorDocs from '../../reference/payment-processor/openapi.yaml';
import studioManagerDocs from '../../reference/studio-manager/openapi.yaml';

class Docs extends Component {
  constructor() {
    super();
    this.state = {
      api: false,
    };
  }

  loadDoc = (doc) => {
    this.setState({ api: doc.file });
  };
  render() {
    const { api } = this.state;
    return (
      <React.Fragment>
        <header style={style.header}>
          {apiDocs.map((doc) => {
            return (
              <button
                style={style.button}
                key={doc}
                onClick={() => this.loadDoc(doc)}
              >
                {doc.name}
              </button>
            );
          })}
        </header>
        {api && <SwaggerUI url={this.state.api} />}
      </React.Fragment>
    );
  }
}

const apiDocs = [
  // {
  //   name: 'studio-manager',
  //   file: studioManagerDocs
  // },
  {
    name: 'payment-processor',
    file: paymentProcessorDocs,
  },
  {
    name: 'auth_service',
    file: authServiceDocs,
  },
  {
    name: 'studio-manager',
    file: studioManagerDocs,
  },
];

const style = {
  header: {
    textAlign: 'center',
  },
  button: {
    height: '30px',
    width: '170px',
    marginLeft: '20px',
    fontSize: '16px',
    WebkitBorderRadius: 0,
    WebkitApperance: 'none',
    borderRadius: 0,
  },
  input: {
    border: '1px solid red',
  },
};

export default Docs;
