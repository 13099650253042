import React, { Component } from 'react';
import axios from 'axios';

import logo from './Assets/inspireLogo.png';
import Docs from './Docs';

class Auth extends Component {
  constructor() {
    super();
    this.state = {
      auth: false,
      invalid: false
    };
  }

  async componentDidMount() {
    const docsAPIKey = await localStorage.getItem('docsAPIKey');
    if (docsAPIKey && docsAPIKey.length) {
      this.setState({
        val: docsAPIKey,
      })
      this.handleSubmit();
    }
  }

  handleChange = event => {
    this.setState({ val: event.target.value });
  };

  handleSubmit = async event => {
    const { val } = this.state;
    if (event) {
      event.preventDefault();
    }
    const axiosConfig = {
      headers: {
        'x-docs-auth': val
      }
    };
    try {
      const res = await axios.post(
        'https://inspirefitness.com/auth/docs',
        {},
        axiosConfig
      );
      if (res.status === 200) {
        this.setState({ auth: true });
        localStorage.setItem('docsAPIKey', val);
      }
    } catch (error) {
      this.setState({ val: '', invalid: true });
      alert('Unauthorized');
    }
  };

  render() {
    const { auth, val, invalid } = this.state;
    if (!auth) {
      return (
        <div style={style.container}>
          <div style={style.main}>
            <img style={style.logo} src={logo} alt="logo" />
            <form onSubmit={this.handleSubmit}>
              <label>
                <input
                  style={invalid ? style.invalidInput : style.input}
                  type="text"
                  value={val}
                  onChange={this.handleChange}
                />
              </label>
              <input style={style.button} type="submit" value="Enter" />
            </form>
          </div>
        </div>
      );
    } else {
      return <Docs />;
    }
  }
}

const style = {
  header: {
    textAlign: 'center'
  },
  button: {
    height: '30px',
    width: '150px',
    marginLeft: '20px',
    fontSize: '16px',
    WebkitBorderRadius: 0,
    WebkitApperance: 'none',
    borderRadius: 0
  },
  logo: {
    paddingBottom: '100px'
  },
  input: {
    width: '300px'
  },
  invalidInput: {
    border: '1px solid red',
    width: '300px'
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    backgroundColor: 'black',
    color: 'white'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-25vh'
  }
};

export default Auth;
